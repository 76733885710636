<template>
  <section>
    <v-container>
        <v-text-field v-model="searchQuery" label="Search" outlined></v-text-field>
        <all-transactions-table @showTransaction="doGoToTransaction" :search="searchQuery"></all-transactions-table>
    </v-container>
  </section>
</template>

<script>
import allTransactionsTable from '@/views/admin/transactions/allTransactionsTable.vue'

export default {
  name: 'index',
  components: { allTransactionsTable },
  data: () => ({
    searchQuery: null
  }),
  methods: {
    doGoToTransaction (uuid) {
      this.$router.replace({ name: 'admin-view-transactions', params: { uuid: uuid } })
    }
  }
}
</script>

<style scoped>

</style>
