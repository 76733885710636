<template>
  <section>
    <v-data-table :search="search" :headers="headers" :items="transactions" :items-per-page="perPage" class="elevation-1 show-pointer" :loading="isLoading" @click:row="doClickRow($event)">
      <template v-slot:item.uuid="{ item }">
        <span class="text-uppercase font-weight-bold">#{{ item.uuid.substr(-6) }}</span>
      </template>
      <template v-slot:item.contactedCustomer="{ item }">
        <span v-if="item.contactedCustomer">
          <font-awesome-icon :icon="['fas', 'check']" class="success--text" size="lg"/>
        </span>
        <span v-else>
          <span v-if="!doCheckCustomerContactSatisfaction(item.contactedCustomer, item.createdAt)">
            <font-awesome-icon :icon="['fas', 'face-frown-open']" class="error--text" size="lg"/>
            <span class="ml-2 error--text caption"> More than 2 Days!</span>
          </span>
          <span v-else>
            <font-awesome-icon :icon="['fas', 'xmark']" class="error--text" size="lg"/>
          </span>
        </span>
      </template>
      <template v-slot:item.cents="{ item }">
        <v-chip class="font-weight-bold">
          ${{ item.cents / 100 }}
        </v-chip>
      </template>
      <template v-slot:item.paymentStatus="{ item }">
        <v-chip class="font-weight-bold" :color="doGenerateColorByStatus(item.paymentStatus)">
          {{ doGenerateNiceStatusTitle(item.paymentStatus) }}
        </v-chip>
      </template>
      <template v-slot:item.refunds="{ item }">
        <v-chip class="font-weight-bold" :color="item.refunds.length >= 1 ? 'error' : 'success'">
          {{ item.refunds.length }}
        </v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
          {{ $moment(item.createdAt).format('lll') }}
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  name: 'allTransactionsTable',
  props: {
    perPage: {
      type: Number,
      default: 5
    },
    search: {
      type: String,
      default: null
    }
  },
  data: () => ({
    isLoading: false,
    headers: [
      {
        text: 'Transaction ID',
        align: 'start',
        sortable: false,
        value: 'uuid'
      },
      {
        text: 'Customer',
        value: 'customer.fullName',
        sortable: true
      },
      {
        text: 'Staff Contacted Customer',
        value: 'contactedCustomer',
        sortable: true
      },
      {
        text: 'Cost',
        value: 'cents',
        sortable: false
      },
      {
        text: 'Status',
        value: 'paymentStatus',
        sortable: true
      },
      {
        text: 'Refunds',
        value: 'refunds',
        sortable: true
      },
      {
        text: 'Purchased on',
        value: 'createdAt',
        sortable: true
      }
    ],
    transactions: []
  }),
  methods: {
    doLoad () {
      this.isLoading = true

      this.$services.transaction.doReadAllProcessed().then(transactions => {
        this.transactions = transactions
      }).catch(error => {
        this.$root.$emit('showError', error.message)
      }).finally(_ => {
        this.isLoading = false
      })
    },
    doGenerateColorByStatus (status) {
      let color
      switch (status) {
        case 'requires_payment_method':
          color = 'error'
          break
        case 'requires_confirmation':
          color = 'warning'
          break
        case 'requires_action':
          color = 'warning'
          break
        case 'processing':
          color = 'purple'
          break
        case 'succeeded':
          color = 'success'
          break
        case 'canceled':
          color = 'grey'
          break
        case 'requires_capture':
          color = 'blue'
          break
        case 'captured':
          color = 'teal'
          break
        default:
          color = 'grey'
      }
      return color
    },
    doGenerateNiceStatusTitle (status) {
      let text
      switch (status) {
        case 'requires_payment_method':
          text = 'Payment Failed'
          break
        case 'processing':
          text = 'Processing'
          break
        case 'succeeded':
          text = 'Success'
          break
        case 'canceled':
          text = 'Cancelled'
          break
        default:
          text = status
      }
      return text
    },
    doCheckCustomerContactSatisfaction (status, date) {
      if (!status) {
        return this.$moment().subtract(2, 'days').toDate() <= new Date(date)
      }
    },
    doClickRow (row) {
      this.$emit('showTransaction', row.uuid)
    }
  },
  mounted () {
    this.doLoad()
  }
}
</script>

<style scoped>

</style>
