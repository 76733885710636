var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-data-table',{staticClass:"elevation-1 show-pointer",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.transactions,"items-per-page":_vm.perPage,"loading":_vm.isLoading},on:{"click:row":function($event){return _vm.doClickRow($event)}},scopedSlots:_vm._u([{key:"item.uuid",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("#"+_vm._s(item.uuid.substr(-6)))])]}},{key:"item.contactedCustomer",fn:function(ref){
var item = ref.item;
return [(item.contactedCustomer)?_c('span',[_c('font-awesome-icon',{staticClass:"success--text",attrs:{"icon":['fas', 'check'],"size":"lg"}})],1):_c('span',[(!_vm.doCheckCustomerContactSatisfaction(item.contactedCustomer, item.createdAt))?_c('span',[_c('font-awesome-icon',{staticClass:"error--text",attrs:{"icon":['fas', 'face-frown-open'],"size":"lg"}}),_c('span',{staticClass:"ml-2 error--text caption"},[_vm._v(" More than 2 Days!")])],1):_c('span',[_c('font-awesome-icon',{staticClass:"error--text",attrs:{"icon":['fas', 'xmark'],"size":"lg"}})],1)])]}},{key:"item.cents",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold"},[_vm._v(" $"+_vm._s(item.cents / 100)+" ")])]}},{key:"item.paymentStatus",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":_vm.doGenerateColorByStatus(item.paymentStatus)}},[_vm._v(" "+_vm._s(_vm.doGenerateNiceStatusTitle(item.paymentStatus))+" ")])]}},{key:"item.refunds",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":item.refunds.length >= 1 ? 'error' : 'success'}},[_vm._v(" "+_vm._s(item.refunds.length)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.createdAt).format('lll'))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }